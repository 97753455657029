<!-- 
StepWizard-Component

@Params:
- steps -> Array of all steps, e.g. array{0,1,2,3}
- current -> current child of array, e.g. array[2]
-->

<template>
    <div class="step-wizard">
      <ol>
        <li
          v-for="step in this.steps"
          :key="step.id"
          :class="current.id === step.id ? 'current' : ''"
        >
          <label>{{ step.label }}</label>
        </li>
      </ol>
    </div>
  </template>
  
  <script>
  export default {
    name: "StepWizard",
    props: ["steps", "current"],
  };
  </script>
  
  <style>
  .step-wizard {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  
  .step-wizard ol {
    position: relative;
    overflow: hidden;
    counter-reset: wizard;
    padding-inline-start: 0;
  }
  
  .step-wizard li {
    position: relative;
    float: left;
    text-align: center;
    color: #00a650;
    width: 6rem;
    font-size: 14px;
  }
  
  .step-wizard .current ~ li {
    color: rgb(117, 117, 117);
  }
  
  .step-wizard li::before {
    counter-increment: wizard;
    content: counter(wizard);
    display: block;
    color: #00a650;
    background-color: white;
    border: 2px solid #00a650;
    text-align: center;
    width: 2em;
    height: 2em;
    line-height: 2em;
    border-radius: 2em;
    position: relative;
    margin: auto;
    z-index: 1;
  }
  
  .step-wizard .current ~ li::before {
    color: rgb(117, 117, 117);
    border: 2px solid rgb(117, 117, 117);
  }
  
  .step-wizard li::after {
    content: "";
    display: block;
    width: 110%;
    background-color: #00a650;
    height: 2px;
    position: absolute;
    left: -60%;
    top: 1em;
    z-index: 0;
  }
  
  .step-wizard li:first-child::after {
    display: none;
  }
  
  .step-wizard .current ~ li:after {
    background-color: rgb(117, 117, 117);
  }
  
  @media only screen and (max-width: 900px) and (min-width: 480px) {
    .step-wizard li {
      width: 4rem;
      font-size: 12px;
    }
  }
  
  @media only screen and (max-width: 480px) {
    .step-wizard li {
      width: 1rem;
      font-size: 12px;
    }
  
    .step-wizard ol li label{
      display: none;
    }
  
    .step-wizard li::before {
      background-color: #00a650;
      width: 1em;
      height: 1em;
      content: '';
    }
  
    .step-wizard .current ~ li::before {
      color: rgb(117, 117, 117);
      background-color: #fff;
      border: 2px solid rgb(117, 117, 117);
    }
  
    .step-wizard li::after {
      width: 130%;
      height: 2px;
      left: -130%;
      top: .4rem;
    }
  }
  </style>
  