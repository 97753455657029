<template>
  <MainForm v-if="(this.isUrlValid && this.showLoadingScreen === false)" :resData="resData" :readOnly="readOnly" />
  <LoadingScreen v-if="this.isUrlValid && this.showLoadingScreen === true"/>
  <ConnFailedComp v-else-if="(this.isUrlValid === false)" :failedMessage="failedMessage"/>
</template>

<script>
import MainForm from './components/MainForm.vue'
import ConnFailedComp from './components/ConnFailedComp.vue'
import LoadingScreen from './components/LoadingScreen.vue'
import store from './store'

export default {
  name: 'App',
  components: {
    MainForm,
    ConnFailedComp,
    LoadingScreen
  },
  data() {
    return {
      isUrlValid: null,
      failedMessage: '',
      showLoadingScreen: true,
      resData : {
        name: '',
        description: '',
        form: [],
        length: null,
        schemaId: ''
      },
      readOnly: false
    }
  },
  async mounted(){
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://kit.fontawesome.com/f34872ba9c.js')
    document.head.appendChild(recaptchaScript)

    function strip(str, char) {
      if (typeof str === 'string' || str instanceof String) {
        if(str.substr(-1) === char) {
          str = str.substr(0, str.length - 1);
        }
        if(str.substr(0, 1) === char) {
          str = str.substr(1, str.length);
        }

        return str;
      }

      return false;
    }

    // Split the URL by anchor <form>/<ID>/<token>
    // Schema: 
    // e.g. Review https://form.url.de/#/review/userId/token
    // e.g. Jobs https://form.url.de/#/jobs/jobId/token
    // e.g. Sickleave https://form.url.de/#/sickleave/userId/token
    // Examples:
    // e.g. Review https://form.cms-it.de/#/review/121/djfhdsuofh389hfe89sd
    // e.g. Jobs https://form.cms-it.de/#/jobs/test-job/djfhdsuofh389hfe89sd
    // e.g. Sickleave https://form.cms-it.de/#/sickleave/121/djfhdsuofh389hfe89sd
    let lastPartOfURL = window.location.hash.split("/");
    let token = strip(lastPartOfURL.pop(), '#');
    let id = strip(lastPartOfURL.pop(), '#');
    this.resData.schemaId = strip(lastPartOfURL.pop(), '#');
    if (!this.resData.schemaId || this.resData.schemaId === '') {
      this.resData.schemaId = 'review'; // Backward compatibility
    }
    const VUE_APP_API_URL_DEV = process.env.VUE_APP_API_URL;
    //let url = strip(process.env.VUE_APP_API_URL_DEV, '/') + '/' + (this.resData.schemaId ? this.resData.schemaId + '/' : '') + id + '?token=' + token;
    let url = strip(VUE_APP_API_URL_DEV, '/') + '/' + (this.resData.schemaId ? this.resData.schemaId + '/' : '') + id + '?token=' + token; //DEV URL

    store.userId = id;
    store.userToken = token;
    store.userURL = url
    store.uploadURL = strip(VUE_APP_API_URL_DEV, '/') + '/' + (this.resData.schemaId ? this.resData.schemaId + '/' : '') + id + '/upload'

    await fetch(url)
    .then((response) => {
      if(response.status === 200) {
        response.json()
        .then((data) => {
          
          let result = data.result.data

          //console.log(result)

          if(this.resData.schemaId === 'jobs') {
            this.resData.form = result.configuration.frames

            //console.log(this.resData.form)

            this.resData.formData = result.configuration.frames
            store.formLength = result.configuration.frames.length
            this.resData.form.forEach(element => {
              store.inputFields[element.id] = []
            });

            this.resData.name = data.result.name
            this.resData.description = data.result.description
            document.title = this.resData.name

            for (let index = 0; index < this.resData.form.length; index++) {
              this.resData.form[index].index = index
            }

            store.routes.WelcomePage = false
            store.routes.FormPages = true
          } else if (this.resData.schemaId === 'review') {
            this.resData.formData = data.result.formdata
            this.resData.form = result.form
            this.siteLength = result.form.length
            store.formLength = result.length
            this.resData.form.forEach(element => {
              store.inputFields[element.id] = []
            });

            this.resData.name = data.result.name
            this.resData.description = data.result.description
            document.title = this.resData.name

            for (let index = 0; index < this.resData.form.length; index++) {
              this.resData.form[index].index = index
            }

            if(data.result.readonly) {
              this.readOnly = data.result.readonly
              store.routes.WelcomePage = false
              store.routes.FormPages = true
            }
          } else if (this.resData.schemaId === 'sickleave') {
            /****************************
            * Sickleave formdata code...
            *****************************/
            
            store.routes.WelcomePage = false
            store.routes.FormPages = true
          }
        })
        this.isUrlValid = true
        this.showLoadingScreen = false
      } else if(response.status === 404) {
        this.isUrlValid = false
        this.failedMessage = "404: Seite nicht gefunden..."
      } else if(response.status === 401) {
        this.isUrlValid = false
        this.failedMessage = "401: Token ist ungültig!"
      }
    })
    .catch((e) => {
      console.log('Error: ' + e)
      this.isUrlValid = false
    })
  }
}
</script>

<style>
@font-face {
  font-family: "TitilliumWeb";
  src: local("TitilliumWeb"),   url(./assets/fonts/TitilliumWeb/TitilliumWeb-Regular.ttf) format("truetype");}

body {
  background: #333249;
  font-family: 'TitilliumWeb', Arial, sans-serif;
  text-align: center;
  margin: 0;
}
h3 {
  margin: 40px 0 0;
  font-size: 1.5rem;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>