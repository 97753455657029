<template>
  <form class="inner-form" @submit.prevent="formOnSubmit">
    <div class="form-section">
      <h3>Danke für deine Zeit!</h3>
      <p style="text-align: center;">Danke das du dir die Zeit genommen hast, den Beurteilungsbogen auszufüllen. Das Formular wurde erfolgreich versendet.</p>
      <br>
    </div>
  </form>
</template>

<script>
export default {
  name: 'MainInfos',
  props: ["routes"],
  data() {
    return {
      route: this.routes,
    }
  },
  methods: {
    formOnSubmit() {
      this.route.ThankYouPage = false
      this.route.FirstPage = true
      window.scrollTo(0,0);
    }
  }
}
</script>