<template>
  <header>
    <div class="logo-mobile"><img src="../assets/logo.png"></div>
    <div class="header-title-wrapper">
      <h1>{{ this.resData.name }}</h1>
    </div>
    <div class="logo"><img src="../assets/logo.png"></div>
  </header>
</template>

<script>

export default {
name: "HeaderSection",
props: ["resData"],
}
</script>

<style>
header {
display: flex;
justify-content: space-between;
align-items: center;
margin: 1rem 10%;
}
.logo-mobile {
display: none;
}
.logo img {
width: 250px;
}
.header-title-wrapper h1 {
color: #fff;
}

@media only screen and (max-width: 960px) and (min-width: 700px) {
.header-title-wrapper h1 {
  font-size: 24px;
}
}

@media only screen and (max-width: 700px) {
header {
  display: block;
}
.logo {
  display: none;
}
.logo-mobile {
  display: initial;
}
.logo-mobile img {
  margin-top: 2rem;
  width: 200px;
}
.header-title-wrapper h1 {
  font-size: 24px;
}
}
</style>