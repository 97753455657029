<template>
  <div class="form">
    <div class="logo"><img src="../assets/logo.png"></div>
    <div class="form-wrapper">
      <h1>Ohh Nein... Es ist ein Fehler aufgetreten!</h1>
      <div class="outer-form conn-failed-outer">
        <div class="conn-failed-inner">
          <h3>{{ this.failedMessage }}</h3>
          <p>Bitte überprüfe deine URL oder kontaktieren einen Admin.</p>
        </div>
      </div>
    </div>
    <FooterSection/>
  </div>
</template>
  
<script>
  import formDataJson from "../json/questions-fields.json"
  import store from "./../store.js"
  import FooterSection from "./FooterSection.vue"
  
  export default {
    name: 'MainFrom',
    components: {
      FooterSection
    },
   props: ["failedMessage"],
    data() {
      return {
        formData: formDataJson,
        routes: store.routes
      }
    }
  }
</script>

<style>
.conn-failed-outer {
  padding: 1rem 0;
}
.conn-failed-inner {
  margin: 2rem auto;
}
</style>