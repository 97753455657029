<template>
    <!--
    SPDX-FileCopyrightText: Riccardo D&#x27;Arcangelo - ZeyOS <riccardo.darcangelo@zeyos.com>
    SPDX-License-Identifier: AGPL-3.0-or-later
    -->
	<div class="app-loading-screen">
		<div class="ring-wrapper">
            <div class="ring"></div>
            <span id="loading-text">loading...</span>
        </div>
	</div>
</template>

<script>

export default {
	name: 'LoadingScreen',
}
</script>
<style scoped>
.app-loading-screen {
    height: 100%;
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ring-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ring {
    width: 200px;
    height: 200px;
    position: absolute;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: ring 3s linear infinite;
}
@keyframes ring {
    0%{
        transform: rotate(0deg);
        box-shadow: 1px 5px 2px #0456c8;
    }
    25%{
        transform: rotate(90deg);
        box-shadow: 1px 5px 2px #5aa0fc;
    }
    50%{
        transform: rotate(180deg);
        box-shadow: 1px 5px 2px #fff;
    }
    75%{
        transform: rotate(270deg);
        box-shadow: 1px 5px 2px #5aa0fc;
    }
    100%{
        transform: rotate(360deg);
        box-shadow: 1px 5px 2px #0456c8;
    }
}
.ring::before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    height: 100%;
    width: 100%;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(255,255,255,.3);
}
span {
    color: #fff;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 200px;
    animation: text 3s ease-in-out linear infinite;
}
@keyframes text {
    0%{
        box-shadow: 1px 5px 2px #0456c8;
    }
    25%{
        box-shadow: 1px 5px 2px #5aa0fc;
    }
    50%{
        box-shadow: 1px 5px 2px #fff;
    }
    75%{
        box-shadow: 1px 5px 2px #5aa0fc;
    }
    100%{ 
        box-shadow: 1px 5px 2px #0456c8;
    }
}
</style>