//store

export default {
    userInput: [],
    userURL: '',
    uploadURL: '',
    pageTitle: '',
    userId: '',
    userToken: '',
    routes: {
        WelcomePage: true,
        FormPages: false,
        ThankYouPage: false,
        index: 0
    },
    inputFields: [],
    formLength: 0,
    formType: ''
}