<template>
  <form class="inner-form" id="main-infos" @submit.prevent="formOnSubmit">
    <div class="form-section">
      <p style="font-size: 20px;" v-html="description"></p>
      <br>
      <div class="bottom-area">
        <div class="btn-area" style="visibility: hidden;">
          <button class="back-btn" type="button" @click="getBack"><i class="fa-light fa-chevron-left"></i> Zurück</button>
        </div>
        <p>Nächster Schritt: <b>Leistungseinschätzung</b></p>
        <div class="btn-area">
          <button class="submit-btn" type="submit">Weiter <i class="fa-light fa-chevron-right"></i></button>
        </div>
      </div>
    </div>
  </form>
</template>
    
<script>
  export default {
    name: 'WelcomePage',
    props: ["routes", "resData", "stepWizard"],
    data() {
      return {
        route: this.routes,
        steps: this.stepWizard,
      }
    },
    methods: {
      formOnSubmit() {
        this.route.WelcomePage = false
        this.route.FormPages = true
        this.steps++
        window.scrollTo(0,0);
      }
    },
    computed: {
      description() {
        return this.resData.description
      }
    }
  }
</script>
    
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#main-infos {
  display: flex;
}

@media only screen and (max-width: 700px) {
  .btn-area {
    justify-content: right;
  }
}
</style>